import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/HomeView'),
        meta: {
            zhName: '主页',
        },
    },
    {
        path: '/wiseclinic',
        name: 'WiseClinic',
        component: () => import('../views/WiseClinicView.vue'),
        children: [
            {
                path: '/wiseclinic#info',
                component: () => import('../views/WiseClinicView.vue'),
                meta: {
                    zhName: '信息发布',
                },
            },
            {
                path: '/wiseclinic#call',
                component: () => import('../views/WiseClinicView.vue'),
                meta: {
                    zhName: '分诊叫号',
                },
            },
            {
                path: '/wiseclinic#self',
                component: () => import('../views/WiseClinicView.vue'),
                meta: {
                    zhName: '自助服务',
                },
            },
            {
                path: '/wiseclinic#data',
                component: () => import('../views/WiseClinicView.vue'),
                meta: {
                    zhName: '数据分析服务',
                },
            },
        ],
        meta: {
            zhName: '智慧门诊',
        },
    },
    {
        path: '/wiseward',
        name: 'WiseWard',
        component: () => import('../views/WiseWardView.vue'),
        children: [
            {
                path: 'wiseward#iptv',
                component: () => import('../views/WiseWardView.vue'),
                meta: {
                    zhName: '病房IPTV',
                },
            },
            {
                path: 'wiseward#board',
                component: () => import('../views/WiseWardView.vue'),
                meta: {
                    zhName: '病区护理白板',
                },
            },
            {
                path: 'wiseward#call',
                component: () => import('../views/WiseWardView.vue'),
                meta: {
                    zhName: '护理呼叫',
                },
            },
        ],
        meta: {
            zhName: '智慧病房',
        },
    },

    {
        path: '/smartServices',
        name: 'smartServices',
        component: () => import('../views/CloudSmartServices.vue'),
        children: [
            {
                path: 'smartServices#diagnosis',
                component: () => import('../views/CloudSmartServices.vue'),
                meta: {
                    zhName: '云陪诊',
                },
            },
            {
                path: 'smartServices#imaging',
                component: () => import('../views/CloudSmartServices.vue'),
                meta: {
                    zhName: '云影像',
                },
            },
        ],
        meta: {
            zhName: '智慧服务',
        },
    },
    {
        path: '/medicalwaste',
        name: 'MedicalWaste',
        component: () => import('../views/MedicalWasteView.vue'),
        children: [
            {
                path: 'medicalwaste#area',
                component: () => import('../views/MedicalWasteView.vue'),
                meta: {
                    zhName: '区域医废监管平台',
                },
            },
            {
                path: 'medicalwaste#institution',
                component: () => import('../views/MedicalWasteView.vue'),
                meta: {
                    zhName: '医疗机构监管系统',
                },
            },
            {
                path: 'medicalwaste#disposal',
                component: () => import('../views/MedicalWasteView.vue'),
                meta: {
                    zhName: '处置单位监管系统',
                },
            },
        ],
        meta: {
            zhName: '医废管理',
        },
    },
    {
        path: '/wiseaffairs',
        name: 'WiseAffairs',
        component: () => import('../views/WiseAffairsView.vue'),
        meta: {
            zhName: '智慧政务',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/AboutView.vue'),
        meta: {
            zhName: '关于我们',
        },
    },
]

const router = new VueRouter({
    routes,
})

export default router
